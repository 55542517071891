* input::placeholder {
  color: #d5e1ea !important;
  font-size: small;
}

* select {
  box-shadow: none !important;
}

* select::placeholder {
  color: black;
}

* [data-pc-name='dropdown'] {
  box-shadow: none !important;
  color: black !important;
  padding-left: 0.5em;
}

#stateDropdown span {
  padding: 0;
  margin-left: 0.5rem;
}

body {
  background-color: #e7e7e7;
}

.backdrop-blur-lg {
  position: relative;
  min-height: full;
  min-width: full;
}

.backdrop-blur-lg::before {
  content: '';
  position: absolute;
  top: 6rem;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(10px);
  background: rgba(6, 24, 36, 0.7);
  z-index: 2;
  pointer-events: none;
}
.blurred {
  filter: blur(2px);
  pointer-events: none;
  user-select: none;
}

.modal {
  position: relative;
  z-index: 3;
}

/* .custom-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  min-height: 5px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #00426f;
  height: 5px;
  min-height: 5px;
  margin-top: 100px;
  margin-bottom: 380px;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #00426f;
  border-radius: 6px;
  width: 5px;
} */

.blur-screen {
  filter: blur(1px); /* Apply blur filter */
}

.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Adjust the opacity as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .NoScrollBar {
  &::-webkit-scrollbar {
    height: 0;
    width: 0;
    display: none;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
    background: transparent;
  }
  &::-webkit-scrollbar:horizontal {
    height: 0;
    width: 0;
    display: none;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    display: none;
    background: transparent;
  }
} */

.p-customHighlight {
  background-color: #d5e1ea !important;
}

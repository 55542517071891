.custom-password .p-password-input + .p-password-panel .p-password-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

#header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: max-content;
  /* background-image: url(/assets/images/loginBackgroundImage.png); */
  background-size: cover;
  background-position: center;
  overflow: auto;
}